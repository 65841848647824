const fades = document.querySelectorAll(".fade");
const contacts = document.getElementById("contacts");
const nav = document.getElementById("navbar");
const upButton = document.getElementById("up-button");
const fixedButtons = document.querySelectorAll(".fixed-button");
const menuToggle = document.getElementById("menu-toggle");

const fadeCallback = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("fadeIn");
    }
  });
};

const lastPageCallback = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      fixedButtons.forEach((b) => b.classList.add("last-page"));
    } else {
      fixedButtons.forEach((b) => b.classList.remove("last-page"));
    }
  });
};

const fadeObserver = new IntersectionObserver(fadeCallback, { threshold: 0.5 });
const mainButtonObserver = new IntersectionObserver(lastPageCallback, {
  threshold: 0.1,
});

mainButtonObserver.observe(contacts);
fades.forEach((el) => fadeObserver.observe(el));

if (window.scrollY > 50) {
  nav.classList.add("top-nav_hidden");
}

document.addEventListener("scroll", (e) => {
  nav.classList.add("top-nav_transition");

  if (window.scrollY > 50) {
    nav.classList.add("top-nav_hidden");
  }

  if (window.scrollY <= 50) {
    nav.classList.remove("top-nav_hidden");
  }

  if (window.scrollY > 200) {
    upButton.classList.remove("up-button_hidden");
  }

  if (window.scrollY <= 200) {
    upButton.classList.add("up-button_hidden");
  }
});

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function scrollToElement(id) {
  const el = document.getElementById(id);
  menuToggle.checked = false;
  if (el) {
    // document.querySelector("html").style.scrollSnapType = "none";
    el.scrollIntoView();

    // setTimeout(() => {
    //   if (!isMobile()) {
    //     document.querySelector("html").style.scrollSnapType = "y mandatory";
    //   }
    // }, 1000);
  }
}

function goToMain() {
  // document.querySelector("html").style.scrollSnapType = "none";

  window.scrollToTop();

  // setTimeout(() => {
  // if (!isMobile()) {
  // document.querySelector("html").style.scrollSnapType = "y mandatory";
  // }
  // }, 1100);
}

function isMobile() {
  return window.innerWidth < 769;
}

(function mobileClass() {
  if (!isMobile()) {
    document.querySelector("html").style.scrollSnapType = "y mandatory";
  }
})();

window.scrollToTop = scrollToTop;
window.scrollToElement = scrollToElement;
window.goToMain = goToMain;
